<template>
  <div>
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">My Monarch</div>
          </a>
        </li>

        <li @click="handleToggleBoardStyle" class="icon">
          <a href="#">
            <span class="tooltip">Switch Board</span>
            <span><i class="fa-solid fa-bolt text-yellow-500"></i></span>
          </a>
        </li>

        <li @click="handleCheckNotifications" class="icon">
          <a href="#">
            <span class="tooltip">Notifications</span>
            <span><i class="fa-solid fa-bell" :style="bellStyle"></i></span>
          </a>
        </li>

        <li @click="showSpecialAirportsDialog = true" class="icon">
          <a href="#">
            <span class="tooltip">Special Airports</span>
            <span><i class="fa-solid fa-tower-control text-blue-500"></i></span>
          </a>
        </li>

        <li @click="handleShowEmployeeActivityReport" class="icon">
          <a href="#">
            <span class="tooltip">Activity Report</span>
            <span
              ><i class="fa-solid fa-file-invoice-dollar text-green-500"></i
            ></span>
          </a>
        </li>

        <li @click="zoomIn" class="icon" v-if="boardStyle === 0">
          <a href="#">
            <span class="tooltip">Zoom In</span>
            <span><i class="fa-solid fa-magnifying-glass-plus"></i></span>
          </a>
        </li>
        <li @click="zoomOut" class="icon" v-if="boardStyle === 0">
          <a href="#">
            <span class="tooltip">Zoom Out</span>
            <span><i class="fa-solid fa-magnifying-glass-minus"></i></span>
          </a>
        </li>

        <li @click="getData" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>
      </ul>
    </nav>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <!-- DATE FILTER DIALOG -->
    <r-modal v-if="showDateFilterDialog" @close="showDateFilterDialog = false">
      <div v-if="showDateFilterDialog" class="p-5">
        <div class="flex justify-between mb-2">
          <div class="text-2xl font-bold">Date Filter</div>
          <button @click="showDateFilterDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="flex">
          <div>
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Start Date</label
            >

            <input
              class="block w-32 rounded-md border-0 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="startDate"
              type="date"
              id="date-filter"
            />
          </div>

          <div class="ml-3">
            <label
              for="date-filter"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Number of Days</label
            >

            <select
              class="block w-24 py-2 rounded-md border-0 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              v-model="numberOfDays"
            >
              <option
                v-for="option in numberOfDaysOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex justify-end mt-1">
          <button
            @click="handleSaveDateFilter"
            class="btn bg-blue-500 text-white"
          >
            Apply Filter
          </button>
        </div>
      </div>
    </r-modal>

    <!-- EMPLOYEE INFORMATION DIALOG -->
    <r-modal
      v-if="showEmployeeInformationDialog"
      @close="showEmployeeInformationDialog = false"
    >
      <employee-information
        :employee="employee"
        @close="showEmployeeInformationDialog = false"
        @employeeProfileButtonClick="handleNavigateToEmployeeProfile"
      ></employee-information>
    </r-modal>

    <!-- SPECIAL AIRPORTS DIALOG -->
    <r-modal
      v-if="showSpecialAirportsDialog"
      @close="showSpecialAirportsDialog = false"
    >
      <SpecialAirports @hide="showSpecialAirportsDialog = false" />
    </r-modal>

    <!-- FLIGHT ASSIGNMENT INFORMATION DIALOG -->
    <r-modal
      v-if="showFlightAssignmentInformationDialog"
      @close="showFlightAssignmentInformationDialog = false"
    >
      <FlightAssignmentInformation
        v-if="flightAssignment"
        :flightAssignment="flightAssignment"
        @close="hideFlightAssignmentInformationDialog($event)"
      ></FlightAssignmentInformation>
    </r-modal>

    <!-- GROUND ASSIGNMENT INFORMATION DIALOG -->
    <r-modal
      v-if="showGroundAssignmentInformationDialog"
      @close="showGroundAssignmentInformationDialog = false"
    >
      <ground-assignment-information
        v-if="groundAssignment"
        :groundAssignment="groundAssignment"
        @hide="hideGroundAssignmentInformationDialog($event)"
      >
      </ground-assignment-information>
    </r-modal>

    <!--NOTIFICATION  INFORMATION DIALOG -->
    <r-modal
      v-if="showNotificationInformationDialog"
      @close="showNotificationInformationDialog = false"
    >
      <div v-if="assignment" class="border-l-8 border-2 border-blue-500 p-5">
        <div
          class="flex justify-between text-xl border-b border-gray-500 py-2 mb-5"
        >
          <div class="flex">
            <h2 class="font-bold">Assignment</h2>
          </div>

          <button
            @click="handleHideNotificationInformationDialog"
            small
            icon
            class="ml-3"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div v-if="assignment.type === 'Flight'">
          <div>
            {{ formatAssignment(assignment) }}
          </div>

          <div class="flex my-1" v-if="assignment.employee">
            <h2 class="font-bold">
              {{
                `${assignment.employee.surname}, ${assignment.employee.givenName}`
              }}
            </h2>
            <h3 class="font-bold text-blue-600 ml-3">
              {{
                `(${assignment.employee.companyName} - ${assignment.employee.companyId.number})`
              }}
            </h3>
          </div>

          <div>
            <span>Phone: </span>
            <span class="text-sm-bold ml-1">
              {{ assignment.employee.mobilePhone }}
            </span>
          </div>

          <div>
            <span>Flight Role:</span
            ><span class="text-base font-bold ml-2">{{ assignment.role }}</span>
          </div>

          <div>
            <span>Assigned by:</span
            ><span class="ml-1 font-bold">{{ assignment.assignedBy }}</span>
          </div>
          <div>
            <span>Last Updated:</span
            ><span class="ml-1 font-bold">{{
              formatDateTime(assignment.updatedAt)
            }}</span>
          </div>

          <div class="horizontal-line-gray my-1"></div>

          <div>Remarks</div>

          <div class="remarks">
            <p
              v-if="assignment.remarks"
              class="user-select-text text-sm text-blue-600 mt-2"
            >
              {{ assignment.remarks }}
            </p>
          </div>

          <div class="horizontal-line-gray my-1"></div>
          <h3>Notification</h3>
          <div v-if="assignment.notification">
            <div>
              <span>Sent by:</span
              ><span class="ml-1 font-bold">{{
                assignment.notification.sentBy
              }}</span>
            </div>
            <div>
              <span>Sent on:</span
              ><span class="ml-1 font-bold">{{
                `${formatDateTime(assignment.notification.updatedAt)} Z`
              }}</span>
            </div>
            <div v-if="assignment.notification.acknowledged">
              <span>Acknowledged on:</span
              ><span class="ml-1 font-bold text-green-500">
                {{
                  `${formatDateTime(assignment.notification.acknowledgedAt)} Z`
                }}
              </span>
            </div>
            <div v-else>
              <span class="font-bold text-red-500">Not Acknowledged</span>
            </div>
            <div
              v-if="assignment.notificationStatus === 'acknowledged-changed'"
            >
              <span>Warning:</span
              ><span class="ml-1 font-bold text-red-500">
                Assignment changed since last notification
              </span>
            </div>
          </div>
          <div v-else>
            <span class="font-bold text-red-500">No notification sent</span>
          </div>
        </div>
        <div v-else>
          <div class="flex my-1">
            <h2 class="font-bold">
              {{
                `${assignment.employee.surname}, ${assignment.employee.givenName}`
              }}
            </h2>
            <h3 class="font-bold text-blue-600 ml-3">
              {{
                `(${assignment.employee.companyName} - ${assignment.employee.companyId.number})`
              }}
            </h3>
          </div>

          <div class="flex justify-between mb-2">
            <div class="text-lg font-bold lg:text-3xl">
              {{ `${formatAssignment(assignment)}` }}
            </div>
          </div>

          <div class="horizontal-line-gray my-1"></div>

          <div>
            <span>Period:</span
            ><span class="text-base font-bold ml-2">
              {{
                `${formatDateTime(assignment.startTime)} -  ${formatDateTime(
                  assignment.endTime
                )}`
              }}</span
            >
          </div>

          <div>
            <span>Employee Phone:</span
            ><span class="text-base font-bold ml-2">{{
              assignment.employee.mobilePhone
            }}</span>
          </div>

          <div>
            <span>Assigned by:</span
            ><span class="text-base font-bold ml-2">{{
              assignment.assignedBy
            }}</span>
          </div>
          <div>
            <span>Last updated:</span
            ><span class="text-base font-bold ml-2">{{
              formatDateTime(assignment.updatedAt)
            }}</span>
          </div>

          <div class="horizontal-line-gray my-1"></div>

          <div>Remarks</div>
          <div class="remarks">
            <p
              v-if="assignment.remarks"
              class="user-select-text text-sm text-blue-600 mt-2"
            >
              {{ assignment.remarks }}
            </p>
          </div>
          <div class="horizontal-line-gray my-1"></div>
          <h3>Notification</h3>
          <div v-if="assignment.notification">
            <div>
              <span>Sent by:</span
              ><span class="ml-1 font-bold">{{
                assignment.notification.sentBy
              }}</span>
            </div>
            <div>
              <span>Sent on:</span
              ><span class="ml-1 font-bold">{{
                `${formatDateTime(assignment.notification.updatedAt)} Z`
              }}</span>
            </div>
            <div v-if="assignment.notification.acknowledged">
              <span>Acknowledged on:</span
              ><span class="ml-1 font-bold text-green-500">
                {{
                  `${formatDateTime(assignment.notification.acknowledgedAt)} Z`
                }}
              </span>
            </div>
            <div v-else>
              <span class="font-bold text-red-500">Not Acknowledged</span>
            </div>
            <div
              v-if="assignment.notificationStatus === 'acknowledged-changed'"
            >
              <span>Warning:</span
              ><span class="ml-1 font-bold text-red-500">
                Assignment changed since last notification
              </span>
            </div>
          </div>
          <div v-else>
            <span class="font-bold text-red-500">No notification sent</span>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- STATION ASSIGNMENT INFORMATION DIALOG -->
    <r-modal
      v-if="showStationAssignmentInformationDialog"
      @close="showStationAssignmentInformationDialog = false"
    >
      <station-assignment-information
        v-if="stationAssignment"
        :groundAssignment="stationAssignment"
        @close="hideStationAssignmentInformationDialog($event)"
      >
      </station-assignment-information>
    </r-modal>
    <!-- BOARD -->
    <div class="board" ref="board">
      <!-- LEFT PANEL -->
      <div v-if="boardStyle === 0 && employee" class="left-panel">
        <div class="left-panel-header bg-gray-500-l2">
          <div class="text-lg font-bold">
            <!-- {{ `${employee.surname}, ${employee.givenName.substring(0, 1)}` }} -->
          </div>
        </div>
        <div
          class="left-panel-row bg-gray-500-l4 cursor-pointer"
          @click="handleShowEmployeeInformationDialog(employee)"
          :style="{ height: `${rowHeight}px` }"
        >
          <div class="text-base text-align text-bold text-truncate">
            {{ `${employee.surname}, ${employee.givenName.substring(0, 1)}` }}
          </div>
          <div class="text-xs text-truncate">
            {{ `${employee.jobTitle}` }}
          </div>
          <div class="text-xs text-truncate">
            {{ `${employee.companyName}` }}
          </div>
        </div>
      </div>
      <!-- RIGHT PANEL -->
      <div v-if="boardStyle === 0 && employee" class="right-panel-container">
        <div class="right-panel">
          <!-- Header -->
          <div
            class="right-panel-header bg-gray-500-l4 cursor-pointer"
            @click="handleOpenDateFilter(item)"
            v-for="(item, index) in dateFilter.days"
            :key="index"
            :style="{
              top: 0,
              left: `${(1440 / scale) * index}px`,
              width: `${dayWidth}px`,
            }"
          >
            {{ formatHeaderDate(item) }}
          </div>

          <!-- Day Lines -->
          <div
            class="day-line"
            v-for="(day, index) in dateFilter.days"
            :key="day"
            :style="{
              left: `${(1440 / scale) * index}px`,
              height: `${rowHeight + 50}px`,
            }"
          ></div>

          <!-- Hour Boxes -->
          <div
            class="hour-box text-[10px]"
            :class="{ hidden: marker.value === '00' || scale > 4 }"
            v-for="(marker, index) in dateFilter.hourBoxes"
            :key="marker.key"
            :style="{ left: `${(60 / scale) * index}px` }"
          >
            {{ marker.value }}
          </div>

          <!-- Hour Lines -->
          <div
            class="hour-line"
            :class="{ hidden: marker.value === '00' || scale > 6 }"
            v-for="(marker, index) in dateFilter.hourLines"
            :key="marker.key"
            :style="{
              left: `${(60 / scale) * index}px`,
              height: `${rowHeight + 50 - 43}px`,
            }"
          ></div>

          <!-- Now Box -->
          <div
            class="now-box"
            v-if="showCurrentTime"
            :style="{
              left: `${nowLineLeft}px`,
            }"
          >
            {{ formatTime(new Date()) }}
          </div>

          <!-- Now Line -->
          <div
            class="now-line"
            v-if="showCurrentTime"
            :style="{
              left: `${nowLineLeft}px`,
              height: `${rowHeight + 2}px`,
            }"
          ></div>

          <!-- Employee -->
          <div
            class="right-panel-row"
            :style="{
              top: `50px`,
              width: `${boardWidth}px`,
              height: `${rowHeight}px`,
            }"
          >
            <!-- Bid Days -->
            <div
              v-for="bidday in employee.biddays"
              :key="`${employee._id}-${bidday.year}-${bidday.month}-${bidday.day}`"
              class="bidday-panel"
              :style="getBidDayStyle(bidday)"
            >
              {{ bidday.description }}
            </div>
            <!--Assignments-->
            <div
              v-for="assignment in employee.assignments"
              :key="assignment._id"
            >
              <!-- Flight Assignment -->
              <div
                v-if="assignment.type === 'Flight'"
                @click="
                  handleShowFlightAssignmentInformationDialog(
                    assignment,
                    $event
                  )
                "
                class="board-label-frame"
                :style="{
                  width: `${assignment.duration / scale}px`,
                  left: `${getLocationLeft(
                    assignment.startTime,
                    dateFilter.days[0],
                    scale
                  )}px`,
                }"
              >
                <FlightAssignmentLabel :assignment="assignment" />
              </div>

              <!-- Station Assignment -->
              <div
                v-else-if="assignment.type === 'Station Assignment'"
                @click="
                  handleShowStationAssignmentInformationDialog(
                    assignment,
                    $event
                  )
                "
                class="board-label-frame"
                :style="{
                  width: `${assignment.duration / scale}px`,
                  left: `${getLocationLeft(
                    assignment.startTime,
                    dateFilter.days[0],
                    scale
                  )}px`,
                }"
              >
                <StationAssignmentLabel :assignment="assignment" />
              </div>

              <!-- Ground Assignment -->
              <div
                v-else
                @click="
                  handleShowGroundAssignmentInformationDialog(
                    assignment,
                    $event
                  )
                "
                class="board-label-frame"
                v-bind:style="{
                  width: `${assignment.duration / scale}px`,
                  left: `${getLocationLeft(
                    assignment.startTime,
                    dateFilter.days[0],
                    scale
                  )}px`,
                }"
              >
                <GroundAssignmentLabel :groundAssignment="assignment" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- LIST -->
      <div v-else-if="boardStyle === 1 && employee">
        <div class="flex flex-col justify-between">
          <!-- Data Table -->
          <div class="relative overflow-x-auto">
            <table
              class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
            >
              <thead
                class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
              >
                <tr>
                  <th scope="col" class="px-6 py-3">Assignment</th>
                  <th scope="col" class="px-6 py-3">Assignment Type</th>
                  <th scope="col" class="px-6 py-3">Assigned By</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="assignment in employee.assignments"
                  :key="assignment._id"
                  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td class="px-6 py-2">
                    {{ formatAssignment(assignment) }}
                  </td>
                  <td class="px-6 py-2">{{ assignment.type }}</td>
                  <td class="px-6 py-2">{{ assignment.assignedBy }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- NOTIFICATIONS -->
      <div v-else-if="boardStyle === 2 && employee" class="mx-5">
        <div class="text-2xl my-2 pl-5">Notifications</div>

        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table
            class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="p-4">Select</th>
                <th scope="col" class="px-6 py-3">Assignment</th>
                <th scope="col" class="px-6 py-3">Notification</th>
                <th scope="col" class="px-6 py-3">Updated At</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="assignment in assignmentsWithNotification"
                :key="assignment._id"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td class="w-4 p-4">
                  <div class="flex items-center">
                    <input
                      v-model="selectedAssignments"
                      :id="assignment._id"
                      :value="assignment"
                      type="checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                </td>

                <td class="px-6 py-2">
                  {{ formatAssignment(assignment) }}
                </td>

                <td class="px-6 py-2">
                  <button
                    icon
                    @click="handleShowNotificationInformationDialog(assignment)"
                  >
                    <i
                      class="fa-solid fa-bell"
                      :style="getBellStyle(assignment)"
                    ></i>
                  </button>
                </td>

                <td class="px-6 py-2">
                  {{ formatNotificationSentOn(assignment) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="assignmentsWithNotification.length"
          class="flex justify-end mt-5"
        >
          <button
            v-if="selectedAssignments.length"
            @click="handleAcknowledgeNotifications"
            class="btn bg-green-500 text-white ma-3"
          >
            Acknowledge Selected
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import authentication from "../services/authentication";
import { mixin } from "../mixins/mixin";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import SpecialAirports from "../components/SpecialAirports.vue";
import RSnackbar from "../components/RSnackbar.vue";
import FlightAssignmentLabel from "../components/FlightAssignmentLabel.vue";
import StationAssignmentLabel from "../components/StationAssignmentLabel.vue";
import GroundAssignmentLabel from "../components/GroundAssignmentLabel.vue";
import EmployeeInformation from "../components/EmployeeInformation.vue";
import FlightAssignmentInformation from "../components/FlightAssignmentInformation.vue";
import GroundAssignmentInformation from "../components/GroundAssignmentInformation.vue";
import StationAssignmentInformation from "../components/StationAssignmentInformation.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";

export default {
  name: "myMonarch",
  mixins: [mixin],
  components: {
    SpecialAirports,
    FlightAssignmentLabel,
    StationAssignmentLabel,
    GroundAssignmentLabel,
    FlightAssignmentInformation,
    GroundAssignmentInformation,
    StationAssignmentInformation,
    EmployeeInformation,
    RSnackbar,
    RSpinner,
    RModal,
  },
  data() {
    return {
      loading: false,

      inactivityTimer: null,

      //---------------------------------------Activity Report
      reportAssignments: [],

      //----------------------------------------Board
      boardStyle: 0,
      sliderX: 0,
      scrollX: 0,
      scale: 4,
      assignmentsListDialog: false,
      showActivityReportDialog: false,
      originIata: "", //Used to sort station assignment flights
      paddingBefore: 30,
      paddingAfter: 30,
      rowHeight: 60,

      //------------------------------------------Filters and Settings

      selectedCompany: "",
      selectedBoardType: "",
      boardTypes: ["Cargo", "Passenger", "Combined"],
      searchByStation: false,
      searchByStationCode: "",
      searchByStationCriteria: "Origin or Destination",
      searchByStationDialog: false,
      searchByStationOptions: [
        "Origin",
        "Destination",
        "Origin or Destination",
      ],

      showDateFilterDialog: false,
      startDate: null,
      numberOfDays: 7,
      numberOfDaysOptions: [3, 5, 7, 14, 21, 31],

      //-----------------------------Employee
      showEmployeeInformationDialog: false,
      employee: null,
      confirmed: false,

      //----------------------------Notifications
      assignmentsWithNotification: [],
      assignmentsWithNotificationNotAcknowledged: [],

      assignment: null,
      showNotificationInformationDialog: false,

      //-------------------------------Flight Assignments
      flightAssignment: null,
      showFlightAssignmentInformationDialog: false,
      showGroundAssignmentInformationDialog: false,
      assignmentSearch: "",

      flightAssignmentRemarks: "",

      //-------------------------------Assignments (Shared)
      selectedAssignments: [],

      // -----------------------------------------Ground Assignments

      groundAssignment: null,
      showNewGroundAssignmentDialog: false,

      //-------------------------------Station Assignments
      stationAssignment: null,
      showStationAssignmentInformationDialog: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      //-------------------------------General Warnings
      warnings: [],
      showWarningsDialog: false,

      //-----------------------------Special Airports
      showSpecialAirportsDialog: false,
    };
  },

  created() {
    (async () => {
      this.getData();
    })();
  },

  mounted() {
    this.startInactivityTimer();
    document.addEventListener("click", this.resetInactivityTimer);
  },
  unmounted() {
    this.clearInactivityTimer();
    document.removeEventListener("click", this.resetInactivityTimer);
  },

  computed: {
    auth() {
      return this.$store.state.auth;
    },

    user() {
      return this.$store.state.user;
    },

    showCurrentTime() {
      const now = new Date().getTime();

      return (
        now > new Date(this.dateFilter.start).getTime() &&
        now < new Date(this.dateFilter.end).getTime()
      );
    },

    dateFilter() {
      return this.$store.state.dateFilter;
    },

    monthFilter() {
      return this.$store.getters.monthFilter;
    },

    nowLineLeft() {
      const x = this.getLocationLeft(
        new Date().toISOString(),
        this.dateFilter.start,
        this.scale
      );

      if (x > 0 || x < this.boardWidth) {
        return x;
      } else {
        return 0;
      }
    },
    dayWidth() {
      return 1440 / this.scale;
    },

    boardWidth() {
      return this.dateFilter.days.length * (1440 / this.scale);
    },

    boardHeight() {
      if (this.auth) {
        return this.rowHeight + 50;
      } else {
        return 0;
      }
    },

    bellStyle() {
      if (this.assignmentsWithNotificationNotAcknowledged.length) {
        return { color: "#FF0000" };
      } else {
        return { color: "#00FF00" };
      }
    },

    airports() {
      return this.$store.state.airports;
    },

    assignmentsNotAcknowledged() {
      return this.$store.getters.assignmentsNotAcknowledged;
    },
  },

  methods: {
    startInactivityTimer() {
      this.clearInactivityTimer();
      this.inactivityTimer = setTimeout(() => {
        this.handleAutoLogout();
      }, this.timeoutDuration);
    },

    clearInactivityTimer() {
      if (this.inactivityTimer) {
        clearTimeout(this.inactivityTimer);
      }
    },

    resetInactivityTimer() {
      this.startInactivityTimer();
    },

    async handleAutoLogout() {
      try {
        //---------------------------------------Refresh Token
        this.loading = true;
        const accessToken = await authentication.getAccessToken();
        if (!accessToken) {
          this.loading = false;
          return;
        }

        await api.get("/auth/logout", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        this.loading = false;

        this.$store.commit("updateAuth", null);
        this.$store.commit("updateUser", null);
        clearTimeout(this.inactivityTimer);
        this.$router.push({ name: "home" });
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        this.snackbarText = `Error during auto-logout: ${error}`;

        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
        }, 3000);
      }
    },

    handleShowNotificationInformationDialog(assignment) {
      const status = this.getNotificationStatus(assignment);

      this.assignment = { ...assignment, notificationStatus: status };

      this.showNotificationInformationDialog = true;
    },

    handleHideNotificationInformationDialog() {
      this.showNotificationInformationDialog = false;
      this.assignment = null;
    },

    //--------------------------Employee Information
    handleShowEmployeeInformationDialog() {
      if (this.canReadEmployeeContactInformation) {
        this.showEmployeeInformationDialog = true;
      }
    },

    handleNavigateToEmployeeProfile() {
      this.$router.push({
        name: "employeeUpdate",
        params: { id: this.employee._id },
      });
    },

    getAircraftLocationY(index) {
      if (index === 0) {
        return "50px";
      } else {
        return `${index * 80 + 50}px`;
      }
    },

    getDayLocationX(index) {
      if (index === 0) {
        return "0";
      } else {
        return `${360 * index}px`;
      }
    },

    async getData() {
      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.get(`/notifications/${this.user._id}`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
          params: {
            dateFilterStart: this.dateFilter.start,
            dateFilterEnd: this.dateFilter.end,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.loading = false;
          this.snackbarColor = "red";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.assignmentsWithNotification = res.data.assignments.filter(
          (item) => {
            return item.notification;
          }
        );

        this.assignmentsWithNotificationNotAcknowledged =
          this.assignmentsWithNotification.filter((item) => {
            return !item.notification.acknowledged;
          });

        this.employee = {
          ...this.user,
          assignments: res.data.assignments,
          biddays: res.data.biddays,
        };

        if (this.assignmentsNotAcknowledged.length) {
          this.boardStyle = 2;
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleOpenDateFilter(item) {
      this.startDate = item.substring(0, 10);
      this.numberOfDays = this.dateFilter.days.length;

      this.showDateFilterDialog = true;
    },

    handleSaveDateFilter() {
      const filterStart = new Date(this.startDate).getTime();
      this.showDateFilterDialog = false;

      //Pass filterStart in miliseconds, numberOfDays as integer
      this.$store.commit("updateDateFilter", {
        filterStart,
        numberOfDays: this.numberOfDays,
      });

      const month = new Date(this.startDate).getUTCMonth();
      const year = new Date(this.startDate).getUTCFullYear();

      this.$store.commit("updateMonthFilter", {
        year,
        month,
        period: "Second",
      });

      this.getData();
    },

    //----------------------------------Notifications
    handleCheckNotifications() {
      if (this.assignmentsWithNotification.length) {
        this.boardStyle = 2;
      } else {
        this.snackbarColor = "green";
        this.snackbarText = "No notifications at this time.";
        this.snackbarVisible = true;
      }
    },

    async handleAcknowledgeNotifications() {
      let notification_ids = [];

      this.selectedAssignments.forEach((assignment) => {
        if (assignment.notification) {
          notification_ids.push(assignment.notification._id);
        }
      });

      if (!notification_ids.length) {
        this.snackbarColor = "red";
        this.snackbarText = "Please select assignments to acknowledge";
        this.snackbarVisible = true;
        return;
      }

      let count = 0;

      this.assignmentsNotAcknowledged.forEach((assignment) => {
        const i = notification_ids.findIndex((_id) => {
          return assignment.notification._id === _id;
        });

        if (i >= 0) count++;
      });

      if (count != this.assignmentsNotAcknowledged.length) {
        this.snackbarColor = "red";
        this.snackbarText =
          "You must select the pending notifications before continuing.";
        this.snackbarVisible = true;

        return;
      }

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.put(
          `/notifications/acknowledge`,
          {
            notification_ids,
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.$store.commit("updateAssignmentsNotAcknowledged", []);

        this.selectedAssignments = [];
        this.getData();
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    zoomIn() {
      if (this.scale === 16) {
        this.scale = 8;
      } else if (this.scale === 8) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 2;
      } else {
        this.scale = 1;
      }
    },

    zoomOut() {
      if (this.scale === 1) {
        this.scale = 2;
      } else if (this.scale === 2) {
        this.scale = 3;
      } else if (this.scale === 3) {
        this.scale = 4;
      } else if (this.scale === 4) {
        this.scale = 8;
      } else {
        this.scale = 16;
      }
    },

    getNotificationStatus(assignment) {
      let status = "not-sent";
      let version;

      let startTime = new Date(assignment.startTime).toISOString();
      let endTime = new Date(assignment.endTime).toISOString();

      switch (assignment.type) {
        case "Flight":
          version = `${assignment.aircraftRegistration}|${assignment.flightNumber}|${assignment.originIata}|${startTime}|${assignment.destinationIata}|${endTime}`;
          break;
        case "Travel":
          version = `${assignment.type}|${assignment.originIata}|${startTime}|${assignment.destinationIata}|${endTime}`;
          break;
        default:
          version = `${assignment.type}|${assignment.originIata}|${startTime}|${endTime}`;
          break;
      }

      if (assignment.notification) {
        if (assignment.notification.acknowledged) {
          if (assignment.notification.version === version) {
            status = "acknowledged";
          } else {
            status = "acknowledged-changed";
          }
        } else {
          status = "sent-not-acknowledged";
        }
      }

      return status;
    },

    formatAssignment(assignment) {
      const timeOut = this.formatDateTime(assignment.startTime);
      const timeIn = this.formatDateTime(assignment.endTime);

      switch (assignment.type) {
        case "Flight":
          return `${assignment.role} . ${assignment.flightNumber} . ${assignment.aircraftRegistration} . ${assignment.originIata} - ${assignment.destinationIata} . ${timeOut} Z - ${timeIn} Z`;

        case "Travel":
          return `${assignment.type} . ${assignment.originIata} - ${assignment.destinationIata} . ${timeOut} Z - ${timeIn} Z`;
        case "Station":
          return `Station Assignment . ${assignment.originIata} . ${timeOut} Z - ${timeIn} Z`;

        default:
          return `${assignment.type} . ${assignment.originIata} . ${timeOut} Z - ${timeIn} Z`;
      }
    },

    formatNotificationSentOn(item) {
      if (item.notification) {
        return `${this.formatDateTime(item.notification.updatedAt)}`;
      } else {
        return "Not Sent";
      }
    },

    getBellStyle(assignment) {
      const status = this.getNotificationStatus(assignment);

      switch (status) {
        case "sent-not-acknowledged":
          return { color: "#FF0000" };
        case "acknowledged":
          return { color: "#00FF00" };
        case "acknowledged-changed":
          return { color: "purple" };
        default:
          return { color: "#FF0000", display: "none" };
      }
    },

    handleToggleBoardStyle() {
      switch (this.boardStyle) {
        case 0:
          if (this.employee.assignments.length) {
            this.boardStyle = 1;
          } else {
            this.loading = false;
            this.snackbarColor = "red";
            this.snackbarText = "No assignments to display.";
            this.snackbarVisible = true;
          }

          break;

        default:
          this.boardStyle = 0;
          break;
      }
    },

    async handleSubmitReport() {
      const dt = new Date(this.monthFilter.year, this.monthFilter.month, 1);

      const month = dt.getUTCMonth();

      let report = {
        employee: { ...this.employee },
        year: this.monthFilter.year,
        month,
        period: "Second",
        daysInThisMonth: this.employee.activity.daysInThisMonth,
        daysOff: this.employee.activity.daysOff,
        daysOver20: this.employee.activity.daysOver20,
        daysOverGuarrantee: this.employee.activity.daysOverGuarrantee,
        floatingHolidayDays: this.employee.activity.floatingHolidayDays,
        healthRiskAllowance: this.employee.activity.healthRiskAllowance,
        healthRiskDays: this.employee.activity.healthRiskDays,
        holidayDays: this.employee.activity.holidayDays,
        leaveDays: this.employee.activity.leaveDays,
        loaDays: this.employee.activity.loaDays,
        missionHazard: this.employee.activity.missionHazard,

        //Calculated Perdiem
        perdiemDays: this.employee.activity.perdiemDays,
        perdiemDaysFirstHalf: this.employee.activity.perdiemDaysFirstHalf,
        perdiemDaysSecondHalf: this.employee.activity.perdiemDaysSecondHalf,

        //Adjustment added by user
        perdiemAdjustment: 0,
        internationalPerdiemAdjustment: 0,

        //Total Ajusted Perdiem
        adjustedPerdiemDaysSecondHalf: 0,

        internationalPerdiemDays:
          this.employee.activity.internationalPerdiemDays,
        internationalPerdiemDaysFirstHalf:
          this.employee.activity.internationalPerdiemDaysFirstHalf,
        internationalPerdiemDaysSecondHalf:
          this.employee.activity.internationalPerdiemDaysSecondHalf,

        //Total Ajusted International Perdiem
        adjustedInternationalPerdiemDaysSecondHalf: 0,

        sickDays: this.employee.activity.sickDays,
        totalDays: this.employee.activity.totalDays,
        vacationDays: this.employee.activity.vacationDays,
        workDays: this.employee.activity.workDays,
        standByDays: this.employee.activity.standByDays,
        workDaysFirstHalf: this.employee.activity.workDaysFirstHalf,
        workDaysSecondHalf: this.employee.activity.workDaysSecondHalf,

        missionHazardAdjustment: 0,
        healthRiskAdjustment: 0,

        adjustedDaysOverGuarrantee: 0,
        assignments: this.employee.assignments,
        remarks: this.reportRemarks,
      };
      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.put(`/reports/monthly-activity`, report, {
          headers: { Authorization: "Bearer " + accessToken },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.snackbarColor = "#66BB6A";
        this.snackbarText = `Your report was submited`;
        this.snackbarVisible = true;
        this.showActivityReportDialog = false;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleShowEmployeeActivityReport() {
      const activity = this.getActivityReport(this.employee);
      this.$store.commit("updateEmployee", { ...this.employee, activity });
      this.$router.push({
        name: "myActivityReport",
      });
    },

    //--------------------------------------Flight Assignments

    handleShowFlightAssignmentInformationDialog(flightAssignment) {
      this.flightAssignment = {
        ...flightAssignment,
      };

      this.showFlightAssignmentInformationDialog = true;
    },

    async hideFlightAssignmentInformationDialog(e) {
      this.showFlightAssignmentInformationDialog = false;
      this.flightAssignment = null;

      if (e.action === "refresh") {
        this.getData();
      }
    },

    //-----------------------Ground Assignments

    async handleShowGroundAssignmentInformationDialog(groundAssignment) {
      this.groundAssignment = {
        ...groundAssignment,
      };

      this.showGroundAssignmentInformationDialog = true;
    },

    async hideGroundAssignmentInformationDialog(refreshData) {
      this.showGroundAssignmentInformationDialog = false;
      this.groundAssignment = null;
      if (refreshData) {
        await this.getData();
      }
    },

    //-----------------------Station Assignments
    async handleShowStationAssignmentInformationDialog(stationAssignment, e) {
      e.stopPropagation();

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        let flights;
        this.loading = true;

        const res = await api.post(
          "/flights/station-assignment",
          {
            flightIds: stationAssignment.flightIds,
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        this.loading = false;

        flights = res.data.flights.sort(this.compareFlights);

        this.stationAssignment = {
          ...stationAssignment,
          flights,
        };

        this.showStationAssignmentInformationDialog = true;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    hideStationAssignmentInformationDialog(refreshData) {
      this.showStationAssignmentInformationDialog = false;
      this.stationAssignment = null;
      if (refreshData) {
        this.getData();
      }
    },

    //------------------------Formatters and Stylers

    formatDate(miliseconds) {
      return new Date(miliseconds).toISOString().substring(0, 10);
    },

    formatDateShort(miliseconds) {
      const date = new Date(miliseconds);

      let options = {
        timeZone: "UTC",
        // year: "numeric",
        month: "short",
        day: "numeric",
        // hour: "numeric",
        // minute: "numeric",
        // hourCycle: "h23",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    formatTime(miliseconds) {
      return new Date(miliseconds).toISOString().substring(11, 16);
    },

    formatDateTime(dateTimeString) {
      const date = new Date(dateTimeString);

      let options = {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hourCycle: "h23",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    formatAssignmentSnapshot(assignment) {
      const timeOut = this.formatDateTime(assignment.startTime);
      const timeIn = this.formatDateTime(assignment.endTime);

      switch (assignment.type) {
        case "Flight":
          return `${assignment.role} . ${assignment.flightNumber} . ${assignment.aircraftRegistration} . ${assignment.originIata} - ${assignment.destinationIata} . ${timeOut} Z`;

        case "Travel":
          return `${assignment.type} . ${assignment.originIata} - ${assignment.destinationIata} . ${timeOut} Z - ${timeIn} Z`;
        case "Station":
          return `Station Assignment . ${assignment.originIata} . ${timeOut} Z - ${timeIn} Z`;

        default:
          return `${assignment.type} . ${assignment.originIata} . ${timeOut} Z - ${timeIn} Z`;
      }
    },

    formatHeaderDate(string) {
      const date = new Date(string);

      let options = {
        timeZone: "UTC",
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "long",
        // hour: "numeric",
        // minute: "numeric",
        // hourCycle: "h23",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    getBidDayStyle(bidDay) {
      //Default color "DO" - Day Off
      let backgroundColor = "#E74C3C";

      switch (bidDay.code) {
        case "RES":
          backgroundColor = "#2ECC71";
          break;
        case "VAC":
          backgroundColor = "#A569BD";
          break;
        default:
          break;
      }

      const x =
        (new Date(Date.UTC(bidDay.year, bidDay.month, bidDay.day)).getTime() -
          new Date(this.dateFilter.days[0]).getTime()) /
        1000 /
        60 /
        this.scale;

      const style = {
        top: 0,
        left: `${x}px`,
        height: `${this.rowHeight}px`,
        width: `${this.dayWidth}px`,
        backgroundColor,
      };

      return style;
    },

    async printActivityReport(employee) {
      try {
        const miniRows = [
          "Stand By",
          "Day Off",
          "Vacation",
          "Holiday",
          "Leave",
          "LOA",
        ];

        const pdfDoc = await PDFDocument.create();

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const helveticaFontBold = await pdfDoc.embedFont(
          StandardFonts.HelveticaBold
        );

        pdfDoc.setTitle(
          `${employee.givenName.substring(0, 1)} ${
            employee.surname
          } Act-Rept  - ${this.formatDate(this.dateFilter.endDate).substring(
            3,
            11
          )}`
        );

        pdfDoc.addPage();

        let pages = pdfDoc.getPages();
        let page = pages[0];
        let day;
        let options = {
          x: 50,
          y: 775,
          size: 16,
          font: helveticaFontBold,
          color: rgb(0, 0, 0),
        };

        page.drawText(
          `${this.formatName(employee.givenName, employee.surname, "sub")} - (${
            employee.gatewayCode
          }) - Activity Report for ${this.formatMonthShort(
            this.monthFilter.month
          )} - ${this.monthFilter.year}`,
          options
        );

        //---------------------------------------------------Separator
        options.size = 12;

        options.y -= 10;
        options.x = 50;
        options.font = helveticaFont;
        page.drawText(
          `_________________________________________________________________________`,
          options
        );

        //----------------------HEADER ROW 1
        options.color = rgb(0.15, 0.09, 0.81);
        options.y -= 30;
        options.x = 50;
        page.drawText(
          `Work Days 1-15: ${employee.activity.workDaysFirstHalf}`,
          options
        );

        options.x = 225;
        page.drawText(
          `Work Days 16-${employee.activity.daysInThisMonth}: ${employee.activity.workDaysSecondHalf}`,
          options
        );

        options.x = 400;
        page.drawText(
          `Work Days Total: ${employee.activity.workDays}`,
          options
        );

        //----------------------HEADER ROW 2
        options.y -= 15;
        options.x = 50;
        page.drawText(`Vacation: ${employee.activity.vacationDays}`, options);

        options.x = 225;
        page.drawText(
          `Floating Holidays: ${employee.activity.floatingHolidayDays}`,
          options
        );

        options.x = 400;
        page.drawText(`Holidays: ${employee.activity.holidayDays}`, options);

        //----------------------HEADER ROW 3
        options.y -= 15;
        options.x = 50;
        page.drawText(`Sick: ${employee.activity.sickDays}`, options);

        options.x = 225;
        page.drawText(`LOA Days: ${employee.activity.loaDays}`, options);

        options.x = 400;
        page.drawText(`Leave: ${employee.activity.leaveDays}`, options);

        options.y -= 15;
        options.x = 50;
        options.color = rgb(0, 0, 0);
        page.drawText(`Total Days: ${employee.activity.totalDays}`, options);

        options.x = 225;
        page.drawText(
          `Perdiem: ${employee.activity.combinedPerdiemDays}`,
          options
        );

        options.x = 400;
        page.drawText(
          `Days off/STBY: ${employee.activity.daysOff}/${employee.activity.standByDays}`,
          options
        );

        //----------------------HEADER ROW 6
        options.y -= 15;
        options.x = 50;
        options.color = rgb(1, 0, 0);
        page.drawText(
          `Mission Hazard: ${employee.activity.missionHazard}`,
          options
        );

        options.x = 225;
        page.drawText(
          `COVID Days: ${employee.activity.healthRiskDays}`,
          options
        );

        options.x = 400;
        page.drawText(
          `COVID Allowance: ${employee.activity.healthRiskAllowance}`,
          options
        );

        //---------------------------------------------------Separator
        options.color = rgb(0, 0, 0);
        options.y -= 15;
        options.x = 50;
        page.drawText(
          `_________________________________________________________________________`,
          options
        );

        options.y -= 15;

        let dayPerdiem;

        for (let i = 0; i < employee.activity.days.length; i++) {
          day = employee.activity.days[i];

          //---------------------------------------------------ROW 1
          // Date
          options.x = 50;
          page.drawText(`${day.date} - ${day.data.description}`, options);

          if (!miniRows.includes(day.data.description)) {
            if (day.data.perdiem > 0) {
              dayPerdiem = "Yes";
            } else {
              dayPerdiem = "No";
            }

            //---------------------------------------------------ROW 2
            options.y -= 15;
            // Perdiem
            options.x = 50;
            page.drawText(`Perdiem: ${dayPerdiem}`, options);
            // Hazards,
            options.x = 225;
            page.drawText(`Mission HAZ: ${day.data.missionHazard}`, options);

            // Health Risk
            options.x = 400;
            page.drawText(
              `COVID 19: ${day.data.healthRisk > 0 ? "1" : "0"}`,
              options
            );
          }
          //---------------------------------------------------Separator
          options.y -= 5;
          options.x = 50;
          page.drawText(
            `_________________________________________________________________________`,
            options
          );
          options.y -= 15;

          //If last page
          if (i === employee.activity.days.length - 1) {
            options.x = 350;
            page.drawText(
              `Printed: ${this.formatDateTime(new Date())}`,
              options
            );
          }

          if (options.y - 70 < 50 && i <= employee.activity.days.length) {
            options.x = 50;
            options.y = 50;
            options.color = rgb(0, 0, 0);
            page.drawText(
              `Monarch - Powered by Raiden Solutions Inc.`,
              options
            );

            options.x = 500;
            options.color = rgb(0, 0, 0);
            page.drawText(`page ${pages.length}`, options);

            pdfDoc.addPage();
            pages = pdfDoc.getPages();
            page = pages[pages.length - 1];
            options.x = 50;
            options.y = 775;
          }
        }

        options.x = 50;
        options.y = 50;
        options.color = rgb(0, 0, 0);
        page.drawText(`Monarch - Powered by Raiden Solutions Inc.`, options);

        options.x = 500;
        page.drawText(`page ${pdfDoc.pageCount}`, options);

        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },
  },
};
</script>

<style scoped>
.right-panel-row {
  position: absolute;
  top: 50px;
  left: 0;
  height: 0;
  width: 0;
  border-bottom: 1px solid gray;
  background-color: white;
  overflow: hidden;
}

.right-panel-row:nth-child(odd) {
  background-color: rgb(236, 225, 225);
}
</style>
